<template>
  <div class="c3-integration-container shadow-card radius-36">
    <iframe
      v-if="checked"
      id="c3-iframe"
      class="card"
      width="100%"
      height="100%"
      scrolling="yes"
      allow="midi 'src'; geolocation 'src'; microphone 'src'; camera 'src';
      display-capture 'src'; encrypted-media 'src';"
      :src="
        `https://app.communitycareconnection.com/orders?src=crm&keeplogin=` +
        keepLogging
      "
    ></iframe>
  </div>
</template>
<script>
export default {
  name: "C3Integration",
  data() {
    return {
      keepLogging: false,
      checked: false,
    };
  },
  beforeMount() {
    if (localStorage.getItem("C3_LOGIN")) {
      this.keepLogging = true;
    } else {
      this.keepLogging = false;
    }
    this.checked = true;
    setTimeout(() => {
      localStorage.setItem("C3_LOGIN", true);
    }, 2000);
  },
};
</script>

<style scoped lang="scss">
.c3-integration-container {
  width: 100%;
  height: calc(100vh - 72px);

  #c3-iframe {
    border: 0;
  }
}
</style>

<style>
.profile-c3-integration-main-container {
  margin-top: 92px;
}
</style>
